import React from 'react'
import EngineeringTemplate from 'components/templates/EngineeringTemplate/EngineeringTemplate'

export default function EngineeringDesignPage() {
  const seoKeywords = ['SEO0', 'SEO1', 'SEO2']

  const productName = 'Progettazione'

  const description =
    '• Impianti idroelettrici di taglie dai kW ai MW, con turbine <b>Francis, Pelton, Kaplan, Semi-Kaplan, Bulbo e Propeller</b><br> • Impianti eolici<br> • Impianti a biomasse<br> • Impianti fotovoltaici<br> • Impianti e strutture civili ed industriali<br> • Antincendio (CPI) e gestione emergenze<br> • Sistemi produttivi e/o di servizi<br> • Progettazione, adeguamento e collaudo macchine ed attrezzature'

  return (
    <>
      <EngineeringTemplate seoKeywords={seoKeywords} productName={productName} description={description} />
    </>
  )
}
